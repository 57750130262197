.gradient {
  background: linear-gradient(45deg, #ff0000 -50%, #0000ff 150%);
}

.gradientText {
  background: linear-gradient(45deg, #ff0000 -50%, #0000ff 150%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@keyframes backgroundAnimation {
  0% {
    background: #ff0000;
  }
  100% {
    background: white;
  }
}

.flashing {
  animation: backgroundAnimation;
  animation-iteration-count: infinite;
  animation-duration: 2s;
}

image {
  max-width: 100%;
}

.pulsating-circle {
  position: absolute;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 30px;
  height: 30px;

  &:before {
    content: "";
    position: relative;
    display: block;
    width: 300%;
    height: 300%;
    box-sizing: border-box;
    margin-left: -100%;
    margin-top: -100%;
    border-radius: 45px;
    background-color: #7d0082;
    animation: pulse-ring 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
  }

  &:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    width: 100%;
    height: 100%;
    background-color: white;
    border-radius: 15px;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
    animation: pulse-dot 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s infinite;
  }
}

@keyframes pulse-ring {
  0% {
    transform: scale(0.33);
  }
  80%,
  100% {
    opacity: 0;
  }
}

@keyframes pulse-dot {
  0% {
    transform: scale(0.8);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.8);
  }
}
